<template>
  <div class="mb-2 mt-3">
    <v-toolbar class="breadcrumb-style ma-3">
      <v-toolbar-title class="d-flex" style="margin-left: 5px" v-if="!$vuetify.breakpoint.xs">
        <span class="text-wrap-style" style="font-size:32px;color: #424242;;font-weight: 400;">{{ $t(page_title) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex">
        <span style="font-size:16px;color: #424242;">{{ items[0].sidebar_tilte ?  $t(items[0].sidebar_tilte) : "" }}</span>
      </div>
      <div v-for="(item,i) in items" :key="i" @click="$router.push(item.href)">
        <span
          style="font-size:16px;"
          class="ml-1 mr-1"
          :style="[item.href ? {'cursor': 'pointer'}: {}, items[items.length-1].text == item.text ? {'color': '#A6CC39'} : {'color': '#424242'}]"
        >{{ $t(item.text) }}</span>
        <!-- item.text == page_title || -->
        <!-- items.length-1 == i -->
        <span v-if=" items.length-2 == i" style="color: #424242;">/</span>        
      </div>

      <v-divider
        v-if="item_no"
        class="ml-2"
        vertical
        inset
        style="border-color: #424242;opacity: 0.2;max-height: calc(100% - 20px);"
      ></v-divider>
      <div class="d-flex ml-4 mr-4" v-if="item_no">
        <span style="font-size: 16px;color: #A6CC39;">{{ item_no }} {{ $t("Items") }}</span>
      </div>
      
      <v-divider
        v-if="item_btn_add"
        vertical
        inset
        class="ml-1"
        style="border-color: #424242;opacity: 0.2;max-height: calc(100% - 20px);"
      ></v-divider>
      <div class="d-flex pl-3" v-if="item_btn_add">
        <v-btn
          dark
          class="add_btn text-capitalize white--text"
          color="#A6CC39"
          style="border-radius: 8px !important"
          @click="Actions('Add')"
        >
          <v-icon class="mr-1 breadcrumb-color" :small="true">mdi-plus-box-outline</v-icon>
          <span style="font-size: 14px;color: #ffffff;">{{ $t(btn_text) }}</span>
        </v-btn>
      </div>

    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array
    },
    page_title: String,
    item_no: Number,
    item_icon: Boolean,
    item_btn_add: Boolean,
    item_btn_edit: Boolean,
    btn_text: String,
  },
  data() {
    return {
      companyName: ""
    };
  },
  mounted() {
    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.companyName = auth.companyName;
    }
  },
  methods: {
    Actions(v) {
      this.$emit("ShowAction", v);
    },
  }
};
</script>

<style scoped>
.text-wrap-style {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;  
  cursor: pointer;
  white-space: pre-line;
  padding-right: 5rem;
}
.breadcrumb-style {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000f;
  border-radius: 16px;
  opacity: 1;
}
</style>

<style>
.breadcrumb-style .v-toolbar__content {
  margin-right: 16px !important;
}
.breadcrumb-color{
  color: #ffffff;
}
.add_btn{
  color: #fff;
  width: 100%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.add_btn:before {
    background-color: #4FB14E;
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5!important;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
}
.add_btn:hover:before {
    background-color: #4FB14E;
    top: 0;
}
.add_btn:hover{
  transition:0.25s;
}
.add_btn:after {
    position: absolute;
    right: 2.34375rem;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(50%);
    -ms-transform: translateY(-50%) translateX(50%);
    transform: translateY(-50%) translateX(50%);
}
</style>